import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { SolutionsHeader, BannerDefault, Section, Title } from "../utils/";
// import solutiontImg from "../utils/img/bcg/solutionsbcg.jpg";
import SolutionsBody from "../components/03-solutions/SolutionsBody";
import { graphql, StaticQuery } from "gatsby";
import ContactList from "../components/06-contact/ContactList";
// import ScrollAnimation from "react-animate-on-scroll";
// import styled from "styled-components";
// import floatingBG from "../utils/img/floatingBG.svg";

const SolutionsPage = ({
  dtitle,
  dsectionTitle,
  dhSlogan,
  dsectionSubtitle,
  dsectionTitle2,
  dsectionSubtitle2
}) => (
  <Layout>
    <SEO
      title="XBound"
      keywords={[
        `smarta kontrakt sverige svenskt företag`,
        ` svenskt företag blockchain utveckling sverige`,
        `Blockchain development smarta kontrakt och blockchain utveckling`,
        `Smart Contract development `,
        `Ethereum Development sverige eterumutveckling `,
        `Solidity Development svenskt företag `,
        `Hyperledger Development Sweden`,
        `ICO STO Development Sweden Stockholm Malmo`,
        `Smart contract Audit Sweden smart kontraktskontroll`,
        `Blockchain Consulting and Development Sweden Stockholm Malmo`,
        `Full-Stack Development Sweden Stockholm Malmo`,
        `svensk blockchain utveckling sverige`,
        `svensk blockchain smart kontraktsutveckling`,
        `Full-Stack Development services sverige`,
        `blockchain company Sweden`,
        `ERC20 Ethereum Token Development Sweden`,
        `Swedish Ethereum Development ICO`,
        `gatsby smarta kontrakt och blockchain utveckling sverige`,
        `react sverige svenskt företag `,
        `Nodejs sverige svenskt företag `,
        `JAMStack Swedish Sweden Stockholm Malmo`,
        `Gatsby Website development Sweden`,
        `React Redux Graphql Front developer svenskt företag `,
        `React Redux Graphql Backend development Sweden Stockholm Malmo`,
        `Cryptocurency development Sweden`,
        `Wallet development Cryptocurency Smart Contract`,
        `Website development Sweden`,
        `Website blog hemsida utveckling Sweden Stockholm Malmo`,
        `Website blog hemsida utveckling svenskt företag`
      ]}
    />
    <StaticQuery
      query={SOLUTIONSTITLE}
      render={data => {
        let solData = data.solutionPage.edges[0];
        const {
          title,
          hSlogan,
          sectionTitle,
          sectionSubtitle,
          sectionTitle2,
          sectionSubtitle2
        } = solData.node;
        return (
          <div>
            <SolutionsHeader>
              <BannerDefault
                title={title || dtitle}
                subtitle={hSlogan || dhSlogan}
              />
            </SolutionsHeader>
            <Section>
              <Title
                title={sectionTitle || dsectionTitle}
                message={sectionSubtitle.sectionSubtitle || dsectionSubtitle}
              />
            </Section>
            <SolutionsBody
              sectionTitle2={sectionTitle2 || dsectionTitle2}
              sectionSubtitle2={
                sectionSubtitle2.sectionSubtitle2 || dsectionSubtitle2
              }
            />
            <ContactList />
          </div>
        );
      }}
    />

    {/* </HomeWrapper> */}
  </Layout>
);
// const ButtonWrapper = styled.button`

// `;
/**************************************/
//          Graphql Constants
/**************************************/

const SOLUTIONSTITLE = graphql`
  {
    solutionPage: allContentfulSolutionsPage {
      edges {
        node {
          title
          hSlogan
          sectionTitle
          sectionSubtitle {
            sectionSubtitle
          }
          sectionTitle2
          sectionSubtitle2 {
            sectionSubtitle2
          }
        }
      }
    }
  }
`;

SolutionsPage.defaultProps = {
  dtitle: "Solutions and Stacks_",
  dhSlogan:
    "We unleash the power of technology by focusing on the latest, innovative and open source technologies and stacks that are market-approved to ensure the quality and agility of our deliverables_",
  dsectionTitle: "The Technologies we use_",
  dsectionSubtitle:
    "We use the cutting-edge technologies and stacks to ensure the uniqueness of your application. Our team conducts a separate analysis of the required technology stack for each and every application to guarantee the best features out of the box and to meet the customer expectations_",
  dsectionTitle2: "A Quick Glance_",
  dsectionSubtitle2:
    "Take a closer look at the description and features of the tools we use_"
};

export default SolutionsPage;

/**
 * used in Services page to list the blockchain services
 * responsive Card icon in circle, subtitle then description
 * responsive using materializecss, we read the icon library name and the icon name fron
 * the headless css (Contenful)
 * By icon lib i mean, lib for react icon like fa ("react-icons/fa")
 */
import React from "react";
import styled from "styled-components";
import { StaticQuery, graphql } from "gatsby";
import { styles } from "../../utils/";

function SolCategoryList() {
  return (
    <SolCategoryListWrapper>
      <div className="container-wide features">
        <div className="features-container">
          <div className="row">
            <StaticQuery
              query={SOLSCATEGORY}
              render={data => {
                const bcServicesDetail = data.solCategory.edges;

                return (
                  <div>
                    {bcServicesDetail.map((solution, index) => {
                      let iconName = solution.node.iconTitle;

                      /* let iconLibName = solution.node.iconLib; */

                      /* let MaterialDesign = handleReactIcon(iconLibName); */

                      /* let mdIcon = MaterialDesign[iconName]; */

                      const imgsrc = require(`../../utils/icon/sol_icons/${iconName}.svg`);
                      return (
                        <div className="col s12 m4 l4" key={index}>
                          <div className="feature-icon">
                            {/* {React.createElement(mdIcon, {
                              className: "material-icons"
                            })} */}
                            <img
                              className="material-icons"
                              src={imgsrc}
                              alt={solution.node.title}
                            />
                          </div>
                          <div className="feature-title">
                            {solution.node.title}
                          </div>
                          <ul className="feature-description">
                            {solution.node.shortDescription.map(
                              (tech, index) => {
                                return <li key={index}>{tech}</li>;
                              }
                            )}
                          </ul>
                        </div>
                      );
                    })}
                  </div>
                );
              }}
            />
          </div>
        </div>
      </div>
    </SolCategoryListWrapper>
  );
}

/**************************************/
//          Graphql Constants
/**************************************/
const SOLSCATEGORY = graphql`
  {
    solCategory: allContentfulSolutionsCategory(
      sort: { fields: [index], order: ASC }
    ) {
      edges {
        node {
          index
          title
          shortDescription
          description {
            description
          }
          iconTitle
          iconLib
        }
      }
    }
  }
`;

/**************************************/
//       ServicesBodyWrapper SC
/**************************************/
const SolCategoryListWrapper = styled.div`
  padding:2rem 0rem;
  background-color:${styles.colors.mainLightGreybgClr};
  .features {
    text-align: center;    
    color: #f62d51;
    margin-top: 0px;
    margin-bottom: 40px;
    /* cursor: pointer; */
  }
  .row .col {
    float: left;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 0.9rem;
  }
  .features .feature-icon {
    height: auto;
    width: 150px;
    /* border: 1px dotted ${styles.colors.mainBlueColor}; */
    /* border: 2px solid #eee; */
    /* background: ${styles.colors.mainWhitebgClr}; */
    /* For Square instead of Circle, just commet out below border-radius */
    /* border-radius: 1000px; */
    text-align: center;
    margin: 15px auto 0;
  }
  .features .feature-icon .material-icons {
    /* color: #1476f2; */

    /* background: linear-gradient(90deg, #134758 50%, #d3d3d3 50%); */

    /* opacity: 0.85; */
    /* font-size: 40px; */
    /* line-height: 96px; */
    /* width: auto; */
    width: 112px;
    height: 112px;
    padding-top: 15px;

    &:hover {
      transform: scale(1.1);
    }
  }
  .features .feature-title {
    padding: 20px 4px 6px 4px;
    text-align: center;
    font-weight: 500;
    letter-spacing:0.13rem;
    font-size:1.13rem;
    text-transform: capitalize;
    color: ${styles.colors.mainBlueColor};
  }

  .features .feature-description li {
    list-style-type: circle;
     /* list-style-type: square; */
    list-style-position: inside;
 /* list-style-image: none; */
  }
  .features .feature-description {
    text-align: left;
    padding-left: 3rem;
    color: ${styles.colors.mainSubtitleClr};
    
    font-size: 1.1em;
    line-height: 1.6em;
    
    /* font-weight: 200; */
    /* text-transform: capitalize; */

    /* color: #6c747f; */
    /* text-align: justify;
    text-justify: inter-word; */
  }
  @media (max-width: 600px) {
    padding:0rem;
    .features .feature-description {
    text-align: center;
    padding-left: 0rem;
    li{
      list-style-type: none;
    }    
    }
    .row .col {
      padding-top:2rem;
    }
  }
  @media (min-width: 1000px) {
 .features .feature-description {    
    padding-left: 8rem;    
    
    }
  }

`;

export default SolCategoryList;

/**************************************/
/* ##Solution:
 * is a class uses to show the solutions or technologies on the solutions.js page.
 * it gets called from th../../utilsy for each map iterate to show one solution
 * the contents are fetching from contentful CMS via Graphql queries.
 * the solution image name provided from the contenful query and then fetched locally*/
/**************************************/
import styled from "styled-components";
import React, { Component } from "react";
import { styles, Section } from "../../utils";
// import ScrollAnimation from "react-animate-on-scroll";
/**************************************/
//          Service Class
/**************************************/
export default class SolutionCard extends Component {
  render() {
    const { solution } = this.props;
    const imgsrc = require(`../../utils/icon/sol_icons/${
      solution.iconTitle
    }.svg`);
    return solution.alignLeft ? (
      <SolutionsDetailWrapper>
        <Section className="row-div grey-color" id="row-div-id">
          <div className="col-text-div-rev" id="a">
            {/* <blockquote> */}
            <p className="subtitle-content">{solution.subtitle}</p>
            <p className="text-content">
              {solution.description.description}
              <span>
                <a href={solution.slug}> visit website..</a>
              </span>
            </p>

            {/* <div className="divider" /> */}
            {/* </blockquote> */}
          </div>
          <div className="col-icon-div-rev" id="b">
            <div className="icon-div">
              <img src={imgsrc} className="icon" alt={solution.title} />
              <p className="icon-title">{solution.title}</p>
            </div>
          </div>
        </Section>
      </SolutionsDetailWrapper>
    ) : (
      <SolutionsDetailWrapper>
        <Section className="row-div" id="row-div-id">
          <div className="col-icon-div" id="b">
            <div className="icon-div">
              <img src={imgsrc} className="icon" alt={solution.title} />

              <p className="icon-title">{solution.title}</p>
            </div>
          </div>
          <div className="col-text-div" id="a">
            {/* <blockquote> */}
            <p className="subtitle-content">{solution.subtitle}</p>
            <p className="text-content">
              {solution.description.description}
              <span>
                <a href={solution.slug}> visit website..</a>
              </span>
            </p>
            {/* <div className="divider" /> */}
            {/* </blockquote> */}
          </div>
        </Section>
      </SolutionsDetailWrapper>
    );
  }
}

const SolutionsDetailWrapper = styled.div`
  padding: 0.5rem;
  /* margin: 0.5rem 0.5rem; */
  /* align-content:center; */
  .grey-color{
  background-color:${styles.colors.mainLightGreybgClr};
  }
  .subtitle-content{
    margin-bottom:1rem;     
    font-size: 1.1rem;
    text-transform: capitalize;
    line-height: 1.56rem;
    font-weight: 310;
    color: #aaa;
  }
  @media (min-width: 768px) {
    .row-div {
      display: flex;
      justify-content: center;
      align-items: center;      
    }
     .col-icon-div {        
      margin-right: 2rem;     
      /* display: flex; */
      max-width: 22%;  
 
     }
     .col-text-div{    
      max-width: 68%;
      padding: 0rem 2rem;
     }
    .col-icon-div-rev{   
      max-width: 20%;
     }
     .col-text-div-rev{    
      max-width: 60%;
      padding: 0rem 2rem;
      
     }         
     .icon-div { 
      /* display: block;  
    margin: auto 1rem;  */
  }
  }
  .icon-div {
    padding: 0.2rem;       
  }
  .icon {
    padding: 0.5rem;
    margin: 0.4rem 0rem;
    width: 10rem !important;
    height: 10rem !important;
    cursor: pointer;
    color:  ${styles.colors.mainBlueColor};
    ${styles.transFunction(undefined, "0.2s")};
    /* &:hover {
      color:  ${styles.colors.mainBlueColor};
    } */
  }
  .icon-title {
   ${styles.letterSpacing({ spacing: "0.1rem" })};
   font-size: 1.2rem;
   font-weight:600;
   color: ${styles.colors.mainTextClr};
   text-align:center;
   text-transform:uppercase;
  }
  .col-text-div{   
   padding:0.5rem;    
   text-align:left;
  }
  .text-content{       
   ${styles.letterSpacing({ spacing: "0.1rem" })};
   line-height:1.4rem;
   font-size: 1rem;   
   text-align: justify;
   color:${styles.colors.mainSubTextClr2};
  }
  .text-title{
   ${styles.letterSpacing({ spacing: "0.1rem" })};
   font-size: 1.2rem;
   font-weight:600;
   color: ${styles.colors.mainTextClr};
   text-align:center;
  }
  .divider{
    padding: 0rem 1rem;
    width: 16rem;
    margin:2rem 1rem;
  }
  @media (max-width:767px){      
  margin: 0.5rem 0rem;
     /* for small screen: change the order of the text and the image to be image on top */
        #row-div-id{
          display:flex;
          flex-flow: column;
        }
        #a{order:2;}        
        #b{order:1;}
      
          .icon-div {
           padding: 0rem;
          }
          .icon-title {            
            font-size:1rem;
            margin:1rem 0rem;
          }
          .text-content{
            padding:0rem 1.5rem;
          }
           .subtitle-content{
            text-align:center;
           }
  }
  `;

import React from "react";
import { Title, Section } from "../../utils/";
// import styled from "styled-components";
import SolutionCard from "./SolutionCard";
import { StaticQuery, graphql } from "gatsby";
// import CardScaleBlue from "./CardScaleBlue";
import SolCategoryList from "./SolCategoryList";

/**************************************/
//          SolutionsBody Class
/**************************************/
export default function SolutionsBody({ sectionTitle2, sectionSubtitle2 }) {
  // const { sectionTitle, sectionSubtitle } = servicesTitle.node;
  return (
    <div>
      {/* <CardScaleBlue /> */}
      <Section>
        <SolCategoryList />
      </Section>
      <Section>
        <Title title={sectionTitle2} message={sectionSubtitle2} />
      </Section>
      <StaticQuery
        query={SOLUTIONS}
        render={data => {
          const solutionsDetail = data.solutionsCard.edges;
          return (
            <div>
              {solutionsDetail.map(solution => {
                return (
                  <SolutionCard
                    key={solution.node.index}
                    solution={solution.node}
                  />
                );
              })}
            </div>
          );
        }}
      />
    </div>
  );
}

/**************************************/
//       SolutionsBodyWrapper SC
/**************************************/
// const SolutionsBodyWrapper = styled.div`
//   width: 80vw;
//   margin: 0rem auto;
//   #pid1 #pid2 {
//     display: none;
//   }
// `;

/**************************************/
//          Graphql Constants
/**************************************/
const SOLUTIONS = graphql`
  {
    solutionsCard: allContentfulSolutionCard(
      sort: { fields: [index], order: ASC }
    ) {
      edges {
        node {
          index
          title
          subtitle
          description {
            description
          }
          alignLeft
          slug
          iconTitle
        }
      }
    }
  }
`;
